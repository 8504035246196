<template>
	<div class="cs-table-block cs-block" :class="styleClass">
		<div class="cs-base-block">
			<div class="container">
				<div class="header-content">
                    <h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"/>
                </div>
				<CSTable class="cs-system-table-block" :id="data.id" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "Table",
	components: {
		CSTable: () => import("../components/CSTable.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('Table')
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
$border_radius: 0px;
    .cs-table-block {
        .header-content {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    ::v-deep {
        .CS-table {
            box-shadow: 3px 6px 24px 0px #B8C1CE4D;
            .table {
                border-radius: $border_radius;
                border: none;
                background: #fff !important;
                margin-bottom: 0;
                .thead {
                    border-radius: $border_radius $border_radius 0 0;
                    background: #F7F7F7;
                    &__item {
                        border: none !important;
                        border-right: 1px solid #E3EDEB !important;
                        padding: 25px !important;
                        background: transparent !important;
                        color: $secondary_text_color !important;
                        text-align: left !important;
                        font-size: 24px !important;
                        font-weight: 700 !important;
                        &:last-child {
                            border-right: none !important;
                        }
                    }
                }
                .tbody {
                    &__row {
                        border-bottom: 1px solid #E3EDEB !important;
                        &:last-child {
                            border-bottom: none !important;
                        }
                        &:nth-child(even) {
                            background: #F7F7F7;
                        }
                    }
                    &__item {
                        border: none !important;
                        border-right: 1px solid #E3EDEB !important;
                        padding: 20px 25px !important;
                        text-align: left !important;
                        font-weight: 400 !important;
    
                        &:last-child {
                            border-right: none !important;
                        }
                    }
                }
            }
        }
    }
</style>
